@import "styles/shared";

.text-control__input {
	font-family: $main-font-family;
	font-size: 1rem;
	color: $color-anthrazit;
	background: $color-white;
	border: 2px solid rgba( 0, 0, 0, .15 );
	border-radius: $border-radius-input;
	padding: .4rem .5rem;
	width: 100%;
	min-height: 40px; // Same as react-select.
	outline: 0;
	transition: all 100ms;

	&[type="email"],
	&[type="text"] {
		appearance: none;
	}

	&[type="tel"] {
		font-variant-numeric: tabular-nums;
	}

	&[type="search"]::-webkit-search-decoration,
	&[type="search"]::-webkit-search-cancel-button,
	&[type="search"]::-webkit-search-results-button,
	&[type="search"]::-webkit-search-results-decoration {
		display: none;
	}

	&:disabled {
		pointer-events: none;
		color: rgba( 0, 0, 0, .15 );
		border-color: rgba( 0, 0, 0, .15 ) !important;
		background-color: rgba( 0, 0, 0, .05 );
	}

	&:invalid {
		box-shadow: none;
	}

	&:hover,
	&:focus {
		border-color: $color-petrol;

		.page-theme--bordeaux & {
			border-color: $color-bordeaux;
		}

		.page-theme--yellow & {
			border-color: $color-yellow;
		}

		.page-theme--olive-green & {
			border-color: $color-olive-green;
		}

		.page-theme--blue & {
			border-color: $color-blue;
		}

		.page-theme--orange-red & {
			border-color: $color-orange-red;
		}

		.page-theme--anthrazit & {
			border-color: $color-anthrazit;
		}
	}

	&:not([value=""]):focus:invalid,
	&--is-invalid {
		border-color: $color-alert-error;
	}
}
