@import "styles/shared";

.top-banner {
	background-color: $color-bordeaux;
	color: $color-white;
	padding: $inner-padding-small / 2 $inner-padding-small;
	order: 1;

	&__loading {
		max-height: 4em;
	}

	@media screen and ( min-width: 800px ) {
		order: 0;
	}

	&__inner {
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			color: $color-white;
		}
	}

	&__item {
		@media screen and ( max-width: 799px ) {
			display: flex;
			width: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	&__title {
		font-family: $black-font-family;

		@media screen and ( min-width: 800px ) {
			margin-right: $inner-padding-small;
		}
	}

	.content-placeholder {
		margin-bottom: 0;
	}
}
