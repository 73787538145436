@keyframes opacity-pulse {
	0%,
	100% {
		opacity: .1;
	}

	50% {
		opacity: .6;
	}
}

@keyframes bounce-in {
	0% {
		transform: scale( 0 );
	}

	60% {
		transform: scale( 1.5 );
	}

	100% {
		transform: scale( 1 );
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
