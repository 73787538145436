@import "styles/shared";

@keyframes content-placeholder {
	0% {
		left: -50%;
	}

	50% {
		left: 75%;
	}

	75% {
		left: 100%;
	}

	100% {
		left: 100%;
	}
}

.content-placeholder {
	display: block;
	background: rgba( 0, 0, 0, .025 );
	position: relative;
	contain: paint;
	transform: translateZ( 0 );
	border-color: transparent !important;
	box-shadow: none !important;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 50%;
		background:
			linear-gradient(
				to right,
				rgba( 0, 0, 0, 0 ),
				rgba( 0, 0, 0, .05 ),
				rgba( 0, 0, 0, 0 )
			);
		animation-duration: 1.5s;
		animation-timing-function: cubic-bezier( .455, .03, .515, .955 );
		animation-delay: inherit;
		animation-iteration-count: infinite;
		animation-name: content-placeholder;

		@media ( prefers-reduced-motion: reduce ) {
			animation: none;
		}
	}
}
