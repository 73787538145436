@import "styles/shared";

.sub-menu-button {
	@include reset-button;

	outline: 0;
	position: relative;
	width: 100%;
	box-sizing: border-box;
	color: currentcolor;

	@media screen and ( max-width: 799px ) {
		padding-right: 1.75rem;
	}

	@media screen and ( min-width: 800px ) {
		display: flex;
		align-items: center;
		gap: .2em;
	}

	.sub-navigation & {
		@media screen and ( min-width: 800px ) {
			display: none;
		}
	}

	&--back {
		padding: 0 0 0 1.75em;
		color: $color-petrol;

		.page-theme--bordeaux & {
			color: $color-bordeaux;
		}

		.page-theme--yellow & {
			color: $color-yellow;
		}

		.page-theme--olive-green & {
			color: $color-olive-green;
		}

		.page-theme--blue & {
			color: $color-blue;
		}

		.page-theme--orange-red & {
			color: $color-orange-red;
		}

		.page-theme--anthrazit & {
			color: $color-anthrazit;
		}
	}
}

svg {
	.sub-menu-button & {
		width: 1.25em;
		transform: translateY( -50% );

		@media screen and ( max-width: 799px ) {
			position: absolute;
			right: 0;
			top: 50%;
		}

		@media screen and ( min-width: 800px ) {
			transform: rotate( 90deg );
			transition: transform .2s;
		}

		/* Inside sub menu for desktop. */
		.sub-navigation & {
			@media screen and ( min-width: 800px ) {
				display: none;
			}
		}
	}

	.sub-navigation__item--has-sub:hover &,
	.sub-menu-button[aria-expanded="true"] & {
		@media screen and ( min-width: 800px ) {
			transform: rotate( -90deg );
		}
	}

	.sub-menu-button--back & {
		right: unset;
		left: 0;
	}
}

.sub-menu-button[aria-expanded="true"] .sub-menu-button__hidden-text-open {
	display: none;
}

.sub-menu-button[aria-expanded="false"] .sub-menu-button__hidden-text-close {
	display: none;
}

.sub-menu-button__text {
	display: block;
	padding: .875rem 0;
	line-height: 1;
	min-height: 2.75rem;

	/*
	* Active state.
	*
	* Using :has() is acceptable here because it is just a aesthetical improvement,
	* not a functional necessity.
	*/
	.sub-menu-button:has(+ .sub-navigation .navigation__item--active) & {
		@media screen and ( max-width: 799px ) {
			@include text-decoration;
			text-decoration-color: $color-petrol;

			.page-theme--bordeaux & {
				text-decoration-color: $color-bordeaux;
			}

			.page-theme--yellow & {
				text-decoration-color: $color-yellow;
			}

			.page-theme--olive-green & {
				text-decoration-color: $color-olive-green;
			}

			.page-theme--blue & {
				text-decoration-color: $color-blue;
			}

			.page-theme--orange-red & {
				text-decoration-color: $color-orange-red;
			}

			.page-theme--anthrazit & {
				text-decoration-color: $color-anthrazit;
			}
		}
	}

	/* Hover & focus state. */
	.sub-menu-button:hover &,
	.sub-menu-button:focus-visible & {
		@include text-decoration;
		text-decoration-color: tint( $color-petrol, 50% );

		.page-theme--bordeaux & {
			text-decoration-color: tint( $color-bordeaux, 50% );
		}

		.page-theme--yellow & {
			text-decoration-color: tint( $color-yellow, 50% );
		}

		.page-theme--olive-green & {
			text-decoration-color: tint( $color-olive-green, 50% );
		}

		.page-theme--blue & {
			text-decoration-color: tint( $color-blue, 50% );
		}

		.page-theme--orange-red & {
			text-decoration-color: tint( $color-orange-red, 50% );
		}

		.page-theme--anthrazit & {
			text-decoration-color: tint( $color-anthrazit, 50% );
		}
	}

	/* Overwrite active state with hover & focus states. */
	.sub-menu-button:has(+ .sub-navigation .navigation__item--active):hover &,
	.sub-menu-button:has(+ .sub-navigation .navigation__item--active):focus & {
		@include text-decoration;
		text-decoration-color: tint( $color-petrol, 50% );

		.page-theme--bordeaux & {
			text-decoration-color: tint( $color-bordeaux, 50% );
		}

		.page-theme--yellow & {
			text-decoration-color: tint( $color-yellow, 50% );
		}

		.page-theme--olive-green & {
			text-decoration-color: tint( $color-olive-green, 50% );
		}

		.page-theme--blue & {
			text-decoration-color: tint( $color-blue, 50% );
		}

		.page-theme--orange-red & {
			text-decoration-color: tint( $color-orange-red, 50% );
		}

		.page-theme--anthrazit & {
			text-decoration-color: tint( $color-anthrazit, 50% );
		}
	}
}
