/* stylelint-disable max-line-length */
$main-font-family: "Helvetica Neue LT W05_55 Roman", "Helvetica Neue LT W05_55 Roman Fallback", sans-serif;
$medium-font-family: "Helvetica Neue LT W05_65 Medium", "Helvetica Neue LT W05_65 Medium Fallback", sans-serif;
$bold-font-family: "Helvetica Neue LT W05_75 Bold", "Helvetica Neue LT W05_75 Bold Fallback", sans-serif;
$heavy-font-family: "Helvetica Neue LT W05_85 Heavy", "Helvetica Neue LT W05_85 Heavy Fallback", sans-serif;
$black-font-family: "Helvetica Neue LT W05_95 Black", "Helvetica Neue LT W05_95 Black Fallback", sans-serif;
/* stylelint-enable max-line-length */

$base-font-size: 16px;
$base-line-height: 1.5;
