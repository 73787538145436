@import "styles/shared";

@keyframes loading-indicator {
	0%,
	80%,
	100% {
		opacity: 0;
	}

	40% {
		opacity: 1;
	}
}

.loading-indicator {
	font-size: 4px;
	line-height: 1;
	display: inline-block;
	vertical-align: middle;

	span {
		animation-duration: 1s;
		animation-delay: 0ms;
		animation-iteration-count: infinite;
		animation-name: loading-indicator;
		animation-timing-function: ease-in-out;
		background-color: currentcolor;
		border-radius: 50%;
		display: inline-block;
		width: 1em;
		vertical-align: top;
		height: 1em;

		&:nth-child(2) {
			animation-delay: 160ms;
			margin-left: 1em;
		}

		&:nth-child(3) {
			animation-delay: 320ms;
			margin-left: 1em;
		}
	}
}
