@import "styles/shared";

@keyframes rotate360 {
	from {
		transform: rotate( 0deg );
	}

	to {
		transform: rotate( 360deg );
	}
}

@keyframes spinner-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

.loader {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	background: rgba( 255, 255, 255, .3 );
	align-items: center;
	justify-content: center;

	&__position-relative {
		position: relative;
	}
}

.loader__spinner {
	position: relative;
	width: 50px;
	max-height: 100%;

	svg {
		height: 100%;
		width: 100%;
		animation: rotate360 2s linear infinite;
		transform-origin: center center;
	}

	circle {
		animation: spinner-dash 1.5s ease-in-out infinite;
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
		stroke-linecap: round;
		stroke: $color-petrol;

		.page-theme--bordeaux & {
			stroke: $color-bordeaux;
		}

		.page-theme--yellow & {
			stroke: $color-yellow;
		}

		.page-theme--olive-green & {
			stroke: $color-olive-green;
		}

		.page-theme--blue & {
			stroke: $color-blue;
		}

		.page-theme--orange-red & {
			stroke: $color-orange-red;
		}

		.page-theme--anthrazit & {
			stroke: $color-anthrazit;
		}
	}
}
