@import "styles/shared";

.base-control {
	margin: 0 0 .9375rem 0;

	@media screen and ( min-width: 600px ) {
		margin: 0 0 1.25rem 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&--inline {
		display: inline;

		.base-control__field {
			display: inline;
		}
	}

	&__error {
		color: $color-alert-error;
		margin-top: .2rem;
		margin-bottom: 0;
	}

	&__help {
		color: tint( $color-anthrazit, 15% );
		font-size: .875rem;
		margin-top: .2rem;
		margin-bottom: 0;

		&::before {
			position: relative;
			top: .15rem;
			height: 1rem;
			width: 1rem;
			content: "";
			background: transparent no-repeat center;
			display: inline-block;
			background-size: contain;
			margin-right: .3125rem;
			background-image: url( ./help.svg );
		}
	}
}

.base-control__label {
	display: block;
	margin-bottom: .2rem;
	color: tint( $color-anthrazit, 15% );
	font-family: $bold-font-family;
	font-weight: 600;
	line-height: 1.5;
}
