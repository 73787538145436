@import "styles/shared";

.modal {
	&--open {
		overflow: hidden;
	}

	&__overlay {
		z-index: 10;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba( 255, 255, 255, .85 );
	}

	&__content {
		position: absolute;
		top: $inner-padding-large;
		left: $inner-padding-large;
		right: $inner-padding-large;
		bottom: $inner-padding-large;
		box-shadow: 0 0 0 2px rgba( 0, 0, 0, .05 ), 0 0 20px 0 rgba( 0, 0, 0, .15 );
		border-radius: 3px 3px 0 0;
		background: $color-white;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		outline: none;
		padding: $inner-padding-large;
		max-width: 1080px;
		margin: 0 auto;
		color: $color-text-copy;

		@media screen and ( max-width: 799px ) {
			top: $inner-padding-small;
			left: $inner-padding-small;
			right: $inner-padding-small;
			bottom: $inner-padding-small;
			padding: $inner-padding-small;
		}

		@media screen and ( max-width: 400px ) {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}

		@media ( min-width: 600px ) {
			animation: modal__appear-animation .2s ease-out;
			animation-fill-mode: forwards;
		}

		&--size-fluid-small,
		&--size-fluid {
			@media ( min-width: 401px ) {
				top: 50%;
				bottom: auto;
				max-height: calc( 100% - #{$inner-padding-large} );
				transform: translateY( -50% );
			}

			@media ( min-width: 600px ) {
				right: auto;
				left: 50%;
				width: auto;
				min-width: 400px;
				max-width: calc( 100% - #{$inner-padding-large} );
				padding: $inner-padding-large;
				transform: translate( -50%, -50% );
			}
		}

		&--size-fluid {
			@media ( min-width: 800px ) {
				min-width: 640px;
			}
		}

		&--size-fluid-small {
			max-width: 500px;
		}

		&--size-full {
			animation: modal__appear-animation-size-full .2s ease-out;
			animation-fill-mode: forwards;
		}

		@media ( min-width: 600px ) and ( prefers-reduced-motion: reduce ) {
			animation-duration: 1ms !important;
		}
	}

	&__header {
		text-align: right;
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		top: 0;
	}

	&__close {
		position: absolute;
		top: .625rem;
		right: .625rem;
		height: 40px;
		width: 40px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: none;
		border: 2px solid transparent;
		border-radius: $border-radius-button;
		cursor: pointer;
		outline: none;
		color: $color-text-copy;

		&:hover,
		&:focus {
			border-color: currentcolor;
		}
	}

	h1 {
		font-size: calc( 1.125rem + .75vw );

		@media only screen and ( min-width: 1000px ) {
			font-size: 1.5625rem;
		}
	}

	hr {
		margin: 1rem 0;
	}
}

@keyframes modal__appear-animation {
	0% {
		transform: translate( -50%, -48% );
		opacity: 0;
	}

	to {
		transform: translate( -50%, -50% );
		opacity: 1;
	}
}

@keyframes modal__appear-animation-size-full {
	0% {
		transform: translate( 0%, 2% );
		opacity: 0;
	}

	to {
		transform: translate( 0%, 0% );
		opacity: 1;
	}
}
