@mixin content-inner {
	width: 100%;
	max-width: 1380px;
	margin: 0 auto;
	padding-left: $inner-padding-small;
	padding-right: $inner-padding-small;

	@media screen and ( min-width: 800px ) {
		padding-left: $inner-padding-medium;
		padding-right: $inner-padding-medium;
	}

	@media screen and ( min-width: 1000px ) {
		padding-left: $inner-padding-large;
		padding-right: $inner-padding-large;
	}
}

@mixin reset-button {
	all: unset;
	cursor: revert;
	outline: revert;
	line-height: 1;
}

@mixin text-decoration {
	text-decoration: underline;
	text-decoration-thickness: 2px;

	/* Use a narrow offset because it looks strange on multi-line items. */
	text-underline-offset: .15em;
}
