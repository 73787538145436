@import "styles/shared";

.global-search-form {
	position: absolute;
	max-width: 1380px;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $color-white;
	z-index: 1;
	display: flex;
	animation: search_form_appear 120ms 1 ease-in-out;
	padding: 0 $inner-padding-small;

	@media screen and ( min-width: 800px ) {
		padding: 0 $inner-padding-medium-alt;
	}

	@media screen and ( min-width: 1000px ) {
		padding: 0 $inner-padding-large;
	}

	@media screen and ( max-width: 799px ) {
		top: 3.75rem;
		bottom: auto;
		padding-top: .625rem;
		padding-bottom: .9375rem;
		box-shadow: 0 -1px 0 rgba( 0, 0, 0, .1 ), 0 10px 20px rgba( 0, 0, 0, .1 );
	}

	&__inner {
		position: relative;
		max-width: 700px;
		margin: auto;
		display: flex;
		flex-basis: 100%;
		flex-wrap: wrap;
	}

	&__label {
		display: block;
		width: 100%;
		margin-bottom: .625rem;
	}

	&__input {
		flex: 1 0 6rem;
		margin: 0 !important;

		input {
			height: 3.125rem;
			padding: .875rem;
			border-radius: $border-radius-input;
		}
	}

	&__submit {
		position: absolute;
		bottom: 0;
		right: -2px; /* Border width */
		height: 3.125rem;
		margin-top: auto !important;
		margin-bottom: 0 !important;
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		background: none;
		border: 0;
		cursor: pointer;
		height: 40px;
		width: 40px;
		z-index: 1;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		@media screen and ( min-width: 800px ) {
			right: $inner-padding-medium-alt;
		}

		@media screen and ( min-width: 1000px ) {
			right: $inner-padding-large;
		}
	}
}

@keyframes search_form_appear {
	0% {
		opacity: 0;
		transform: translate3d( 0, -20px, 0 );
	}

	100% {
		opacity: 1;
		transform: translate3d( 0, 0, 0 );
	}
}
