@import "styles/shared";

.button {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	font-family: $black-font-family;
	font-size: 1rem;
	line-height: 1.25;
	font-weight: 600;
	border: 0;
	border-radius: $border-radius-button;
	cursor: pointer;
	color: $color-petrol;
	box-shadow: inset 0 0 0 2px $color-petrol;
	padding: .875rem 1.25rem 1rem 1.25rem;
	margin: 2px; // 2px for box-shadow on focus.
	white-space: nowrap;
	min-height: 40px; // Same as react-select.
	outline: none;
	background: transparent;

	.page-theme--bordeaux & {
		color: $color-bordeaux;
		box-shadow: inset 0 0 0 2px $color-bordeaux;
	}

	.page-theme--yellow & {
		color: $color-yellow;
		box-shadow: inset 0 0 0 2px $color-yellow;
	}

	.page-theme--olive-green & {
		color: $color-olive-green;
		box-shadow: inset 0 0 0 2px $color-olive-green;
	}

	.page-theme--blue & {
		color: $color-blue;
		box-shadow: inset 0 0 0 2px $color-blue;
	}

	.page-theme--orange-red & {
		color: $color-orange-red;
		box-shadow: inset 0 0 0 2px $color-orange-red;
	}

	.page-theme--anthrazit & {
		color: $color-anthrazit;
		box-shadow: inset 0 0 0 2px $color-anthrazit;
	}

	&:hover {
		color: $color-petrol;
		background-color: tint( $color-petrol, 90% );

		.page-theme--bordeaux & {
			color: $color-bordeaux;
			background-color: tint( $color-bordeaux, 90% );
		}

		.page-theme--yellow & {
			color: $color-yellow;
			background-color: tint( $color-yellow, 90% );
		}

		.page-theme--olive-green & {
			color: $color-olive-green;
			background-color: tint( $color-olive-green, 90% );
		}

		.page-theme--blue & {
			color: $color-blue;
			background-color: tint( $color-blue, 90% );
		}

		.page-theme--orange-red & {
			color: $color-orange-red;
			background-color: tint( $color-orange-red, 90% );
		}

		.page-theme--anthrazit & {
			color: $color-anthrazit;
			background-color: tint( $color-anthrazit, 90% );
		}
	}

	&:focus {
		color: $color-white;
		background: $color-petrol;
		box-shadow:
			inset 0 0 0 1px shade( $color-petrol, 50% ),
			inset 0 0 0 2px $color-petrol,
			0 0 0 2px tint( $color-petrol, 50% );

		.page-theme--bordeaux & {
			color: $color-white;
			background-color: $color-bordeaux;
			box-shadow:
				inset 0 0 0 1px shade( $color-bordeaux, 50% ),
				inset 0 0 0 2px $color-bordeaux,
				0 0 0 2px tint( $color-bordeaux, 50% );
		}

		.page-theme--yellow & {
			color: $color-white;
			background-color: $color-yellow;
			box-shadow:
				inset 0 0 0 1px shade( $color-yellow, 50% ),
				inset 0 0 0 2px $color-yellow,
				0 0 0 2px tint( $color-yellow, 50% );
		}

		.page-theme--olive-green & {
			color: $color-white;
			background-color: $color-olive-green;
			box-shadow:
				inset 0 0 0 1px shade( $color-olive-green, 50% ),
				inset 0 0 0 2px $color-olive-green,
				0 0 0 2px tint( $color-olive-green, 50% );
		}

		.page-theme--blue & {
			color: $color-white;
			background-color: $color-blue;
			box-shadow:
				inset 0 0 0 1px shade( $color-blue, 50% ),
				inset 0 0 0 2px $color-blue,
				0 0 0 2px tint( $color-blue, 50% );
		}

		.page-theme--orange-red & {
			color: $color-white;
			background-color: $color-orange-red;
			box-shadow:
				inset 0 0 0 1px shade( $color-orange-red, 50% ),
				inset 0 0 0 2px $color-orange-red,
				0 0 0 2px tint( $color-orange-red, 50% );
		}

		.page-theme--anthrazit & {
			color: $color-white;
			background-color: $color-anthrazit;
			box-shadow:
				inset 0 0 0 1px shade( $color-anthrazit, 50% ),
				inset 0 0 0 2px $color-anthrazit,
				0 0 0 2px tint( $color-anthrazit, 50% );
		}
	}

	&--is-destructive {
		color: $color-text-destructive !important;
		box-shadow: inset 0 0 0 2px $color-text-destructive !important;

		&:focus,
		&:hover {
			color: $color-text-destructive !important;
			background-color: tint( $color-text-destructive, 90% ) !important;
			box-shadow: inset 0 0 0 2px $color-text-destructive !important;
		}

		&:focus {
			box-shadow:
				inset 0 0 0 1px shade( $color-text-destructive, 50% ),
				inset 0 0 0 2px $color-text-destructive,
				0 0 0 2px tint( $color-text-destructive, 50% ) !important;
		}
	}

	&--is-primary {
		box-shadow: none;
		color: $color-white !important;
		background-color: $color-petrol;

		&:hover {
			background-color: tint( $color-petrol, 20% );
		}

		.page-theme--bordeaux & {
			background-color: $color-bordeaux;

			&:hover {
				background-color: tint( $color-bordeaux, 20% );
			}
		}

		.page-theme--yellow & {
			background-color: $color-yellow;

			&:hover {
				background-color: tint( $color-yellow, 20% );
			}
		}

		.page-theme--olive-green & {
			background-color: $color-olive-green;

			&:hover {
				background-color: tint( $color-olive-green, 20% );
			}
		}

		.page-theme--blue & {
			background-color: $color-blue;

			&:hover {
				background-color: tint( $color-blue, 20% );
			}
		}

		.page-theme--orange-red & {
			background-color: $color-orange-red;

			&:hover {
				background-color: tint( $color-orange-red, 20% );
			}
		}

		.page-theme--anthrazit & {
			background-color: $color-anthrazit;

			&:hover {
				background-color: tint( $color-anthrazit, 20% );
			}
		}

		&.button--is-destructive {
			box-shadow: none;
			color: $color-white !important;
			background-image:
				linear-gradient(
					-180deg,
					rgba( 255, 255, 255, .1 ) 0%,
					rgba( 0, 0, 0, .1 ) 100%
				);
			background-color: $color-text-destructive !important;

			&:focus,
			&:hover {
				background-color: tint( $color-text-destructive, 20% ) !important;
			}
		}
	}

	&--is-rounded {
		border-radius: 100px;
	}

	&--is-white {
		background-color: $color-white !important;
		color: $color-black !important;
	}

	&--is-disabled {
		pointer-events: none;
		cursor: default;
		opacity: .4;
	}

	&--is-large {
		display: block;
		width: 100%;
		max-width: 540px;
		text-align: center;
		margin: 0 auto;
	}

	&--is-small {
		padding: .4375rem .625rem .5rem .625rem;
	}

	&--is-link {
		margin: 0;
		padding: 0;
		box-shadow: none;
		border: none;
		background: none;
		border-radius: 0;
		min-height: 0;
		color: $color-petrol;
		white-space: normal;
		text-align: left;

		&:focus,
		&:hover {
			color: $color-text-copy;
			background: none;
			box-shadow: inset 0 -1px 0 0 $color-text-copy;
		}

		.page-theme--bordeaux & {
			color: $color-bordeaux;
			box-shadow: none;

			&:focus,
			&:hover {
				color: $color-bordeaux;
				background: none;
				box-shadow: inset 0 -1px 0 0 $color-bordeaux;
			}
		}

		.page-theme--yellow & {
			color: $color-yellow;
			box-shadow: none;

			&:focus,
			&:hover {
				color: $color-yellow;
				background: none;
				box-shadow: inset 0 -1px 0 0 $color-yellow;
			}
		}

		.page-theme--olive-green & {
			color: $color-olive-green;
			box-shadow: none;

			&:focus,
			&:hover {
				color: $color-olive-green;
				background: none;
				box-shadow: inset 0 -1px 0 0 $color-olive-green;
			}
		}

		.page-theme--blue & {
			color: $color-blue;
			box-shadow: none;

			&:focus,
			&:hover {
				color: $color-blue;
				background: none;
				box-shadow: inset 0 -1px 0 0 $color-blue;
			}
		}

		.page-theme--orange-red & {
			color: $color-orange-red;
			box-shadow: none;

			&:focus,
			&:hover {
				color: $color-orange-red;
				background: none;
				box-shadow: inset 0 -1px 0 0 $color-orange-red;
			}
		}

		.page-theme--anthrazit & {
			color: $color-anthrazit;

			&:focus,
			&:hover {
				color: $color-anthrazit;
				background: none;
				box-shadow: inset 0 -1px 0 0 $color-anthrazit;
			}
		}

		&.button--is-destructive {
			color: $color-text-destructive !important;

			&:focus,
			&:hover {
				color: $color-text-destructive !important;
				background: none !important;
				box-shadow: inset 0 -1px 0 0 $color-text-destructive !important;
			}
		}
	}

	&--no-outline {
		box-shadow: none !important;
	}

	&--is-tertiary {
		margin: 0;
		padding: 0;
		box-shadow: none !important;
		border: none !important;
		background: none !important;
		border-radius: 0;
		min-height: 0;
		color: $color-petrol;
		white-space: normal;
		text-align: left;

		&:focus,
		&:hover {
			color: $color-text-copy;
			background: none !important;
			box-shadow: none !important;
			text-decoration: underline;
		}
	}

	.loading-indicator {
		margin-left: .5rem;
	}
}
