@import "styles/shared";

/* All navigations. */
.navigation {
	color: $color-text-copy;
	font-family: $medium-font-family;

	ul {
		margin: 0;
		list-style: none;
	}
}

/* All navigations in header. */
.app-header .navigation {
	ul {
		@media screen and ( max-width: 799px ) {
			padding-right: $inner-padding-small;
			padding-left: $inner-padding-small;
		}
	}
}

/* Main navigation. */
.main-navigation {
	.content-placeholder.navigation__item {
		min-height: 2.75rem; /* Height of .sub-menu-button__text to avoid jumping. */
	}

	/* Depth 1. */
	> ul > li {
		@media screen and ( max-width: 799px ) {
			border-bottom: 1px solid $color-border-light-gray;
		}

		&:first-child {
			@media screen and ( max-width: 799px ) {
				border-top: 1px solid $color-border-light-gray;
			}
		}
	}

	.main-navigation__search {
		@media screen and ( max-width: 799px ) {
			display: none;
		}
	}

	@media screen and ( min-width: 800px ) {
		order: 3;
		width: 100%;
		margin-top: .75rem;
	}
}

/* Meta navigation. */
.meta-navigation {
	@media screen and ( min-width: 800px ) {
		order: 1;
	}
}

/* Profile navigation. */
.meta-navigation--profile {
	@media screen and ( min-width: 800px ) {
		order: 2;
		align-self: center;
	}

	@media screen and ( min-width: 800px ) and ( max-width: 999px ) {
		margin-left: 1.5em;
	}

	@media screen and ( min-width: 1000px ) {
		margin-left: 2.5em;
	}

	> ul {
		@media screen and ( min-width: 800px ) {
			column-gap: 1rem;
		}
	}

	li {
		@media screen and ( min-width: 800px ) {
			background: $color-white;
		}
	}

	.meta-navigation__list--logged-in a {
		@media screen and ( min-width: 800px ) {
			padding: .625rem;
			transition: background .2s;

			&:hover,
			&:focus {
				background: rgba( 0, 0, 0, .025 );
			}
		}
	}
}

/* Secondary navigations. */
.meta-navigation,
.profile-navigation {
	font-size: .875rem;
	color: #6f6f6f;
}

/* Desktop visible navigations. */
.main-navigation,
.meta-navigation--meta {
	> ul {
		@media screen and ( min-width: 800px ) {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
		}

		@media screen and ( min-width: 800px ) and ( max-width: 999px ) {
			column-gap: 1.5em;
		}

		@media screen and ( min-width: 1000px ) {
			column-gap: 2.5em;
		}
	}
}

.navigation__item {
	line-height: 1;
	display: block;
	padding: .625rem 0;
	text-decoration: none;

	.navigation & {
		color: currentcolor;
	}

	&:hover,
	&:focus-visible {
		/* IE 11 fallback will be a simple underline. */
		@include text-decoration;
		text-decoration-color: tint( $color-petrol, 50% );

		.page-theme--bordeaux & {
			text-decoration-color: tint( $color-bordeaux, 50% );
		}

		.page-theme--yellow & {
			text-decoration-color: tint( $color-yellow, 50% );
		}

		.page-theme--olive-green & {
			text-decoration-color: tint( $color-olive-green, 50% );
		}

		.page-theme--blue & {
			text-decoration-color: tint( $color-blue, 50% );
		}

		.page-theme--orange-red & {
			text-decoration-color: tint( $color-orange-red, 50% );
		}

		.page-theme--anthrazit & {
			text-decoration-color: tint( $color-anthrazit, 50% );
		}
	}

	&.content-placeholder:hover,
	&.content-placeholder:focus {
		text-decoration: none;
	}

	&--active {
		@include text-decoration;
		text-decoration-color: $color-petrol;

		.page-theme--bordeaux & {
			text-decoration-color: $color-bordeaux;
		}

		.page-theme--yellow & {
			text-decoration-color: $color-yellow;
		}

		.page-theme--olive-green & {
			text-decoration-color: $color-olive-green;
		}

		.page-theme--blue & {
			text-decoration-color: $color-blue;
		}

		.page-theme--orange-red & {
			text-decoration-color: $color-orange-red;
		}

		.page-theme--anthrazit & {
			text-decoration-color: $color-anthrazit;
		}
	}

	@media screen and ( max-width: 799px ) {
		padding: .875rem 0;
	}
}

/* New slide in navigation with sub menus. */
$transition-duration: .4s;

body:has(.app-header__navigation--is-open) {
	position: fixed;
	overflow: hidden;
}

.app-header__navigation {
	outline: 0;
	flex: 1;

	@media screen and ( max-width: 799px ) {
		position: absolute;
		max-width: 100vw;
		overflow-x: hidden;
		top: 100%;
		left: 0;
		right: 0;
		transition: all $transition-duration ease;
		height: min-content;
		max-height: 90vh;
		pointer-events: none;

		&.app-header__navigation--is-open {
			pointer-events: auto;
		}

		&:not(.app-header__navigation--is-open) > div {
			transform: translateX( 100% );
		}
	}
}

/* Navigation content. */
.app-header__navigation-content {
	@media screen and ( max-width: 799px ) {
		-webkit-overflow-scrolling: touch;
		transition: transform $transition-duration ease;
		box-shadow: 0 2px 4px 0 rgba( 0, 0, 0, .4 );
		border-bottom: 1px solid $color-border-light-gray;
	}
}

.app-header__navigation-slider {
	@media screen and ( max-width: 799px ) {
		position: relative;
		top: 100%;
		left: 0;
		width: 100%;
		padding-bottom: $inner-padding-small;
		border-top: 1px solid $color-border-light-gray;
		background: $color-white;
		visibility: visible;
		transition:
			left $transition-duration,
			visibility 0s;

		.app-header__navigation:not(.app-header__navigation--is-open) & {
			visibility: hidden;
			transition:
				left 0s $transition-duration,
				visibility 0s $transition-duration;
		}

		.app-header__navigation--is-sub-open & {
			left: -100%;
			visibility: hidden;
			transition:
				left $transition-duration ease,
				visibility 0s $transition-duration;
		}
	}

	@media screen and ( min-width: 800px ) {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
}

.sub-navigation-wrapper {
	display: contents;
}

.sub-navigation {
	@media screen and ( max-width: 799px ) {
		position: absolute;
		top: -1px; /* Because of border-top. */
		left: 100%;
		width: 100%;
		padding-bottom: $inner-padding-small;
		margin: 0;
		border-top: 1px solid $color-border-light-gray;
		background: $color-white;
		box-shadow: 0 2px 4px 0 rgba( 0, 0, 0, .4 );
		visibility: visible;
		outline: 0;
		transition:
			left $transition-duration ease,
			visibility 0s;

		.sub-menu-button[aria-expanded="false"] + & {
			left: 100%;
			visibility: hidden;
			transition:
				left 0s $transition-duration,
				visibility 0s $transition-duration;
		}

		&.sub-navigation--is-sub-open {
			left: 0;
			visibility: hidden;
			transition:
				left $transition-duration ease,
				visibility 0s $transition-duration;
		}

		.sub-navigation__item--back ~ li {
			padding-left: 1.75em;
		}
	}
}

/* Desktop dropdowns. */
.navigation > ul > li > .sub-navigation-wrapper > .sub-navigation,
.meta-navigation--profile > .meta-navigation__list--logged-in {
	@media screen and ( min-width: 800px ) {
		position: absolute;
		top: calc( 100% + .75rem );
		left: 50%;
		min-width: 200px;
		z-index: 1;
		box-shadow: 0 1px 3px 0 rgba( 0, 0, 0, .4 );
		opacity: 0;
		transform: translateX( -50% ) translateY( -.5rem );
		pointer-events: none;
		transition: opacity .2s, transform .2s;
	}

	&::before {
		@media screen and ( min-width: 800px ) {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 50%;
			display: block;
			width: 1.25rem;
			height: 1.25rem;
			background: $color-white;
			box-shadow: 0 -.25px 3px 0 rgba( 0, 0, 0, .4 );
			transform: translateX( -50% ) translateY( -50% ) rotate( 45deg );
		}
	}

	/* Use pseudo element to bridge gap between button & dropdown. */
	&::after {
		@media screen and ( min-width: 800px ) {
			content: "";
			position: absolute;
			top: -.75rem;
			right: 0;
			bottom: 100%;
			left: 0;
		}
	}

	li {
		@media screen and ( min-width: 800px ) {
			position: relative;
			z-index: 3;
		}
	}
}

.navigation > ul > li > .sub-navigation-wrapper > .sub-navigation {
	@media screen and ( min-width: 800px ) {
		visibility: hidden;
		transition:
			opacity .2s,
			transform .2s,
			visibility .2s;
	}
}

/* Open desktop dropdowns. */
.navigation > ul > .sub-navigation__item--has-sub:hover > .sub-navigation-wrapper > .sub-navigation,
.navigation > ul .sub-menu-button[aria-expanded="true"] + .sub-navigation,
.meta-navigation--profile:hover > .meta-navigation__list--logged-in,
.meta-navigation--profile > .meta-navigation__list--logged-in:focus-within {
	@media screen and ( min-width: 800px ) {
		opacity: 1;
		transform: translateX( -50% ) translateY( 0 );
		pointer-events: all;
	}
}

.navigation > ul > .sub-navigation__item--has-sub:hover > .sub-navigation-wrapper > .sub-navigation,
.navigation > ul .sub-menu-button[aria-expanded="true"] + .sub-navigation {
	@media screen and ( min-width: 800px ) {
		visibility: visible;
		transition:
			opacity .2s,
			transform .2s;
	}
}

.sub-navigation__item {
	@media screen and ( min-width: 800px ) {
		background: $color-white;
	}

	a {
		white-space: nowrap;

		@media screen and ( min-width: 800px ) {
			padding: .875rem .9375rem;
			transition: background .2s;

			&:hover,
			&:focus {
				background: rgba( 0, 0, 0, .025 );
			}
		}
	}

	ul ul ul &--simple a {
		@media screen and ( min-width: 800px ) {
			padding-left: $inner-padding-small * 2;
		}
	}

	&--back {
		@media screen and ( min-width: 800px ) {
			display: none;
		}
	}

	&--group-link {
		/* All nested items. */
		.sub-navigation .sub-navigation & {
			@media screen and ( min-width: 800px ) {
				font-family: $bold-font-family;
			}
		}
	}

	&--has-sub {
		@media screen and ( min-width: 800px ) {
			position: relative;
		}
	}
}

/* Borders for navigation items. */

/* stylelint-disable max-line-length */

/* Depth 1 & 2. */
.navigation > ul > li > .sub-navigation-wrapper > .sub-navigation > .sub-navigation__item--simple,
.navigation > ul > li > .sub-navigation-wrapper > .sub-navigation > .sub-navigation__item--has-sub > ul > .sub-navigation__item--group-link,
.meta-navigation__list--logged-in > li + li {
	@media screen and ( min-width: 800px ) {
		border-top: 1px solid $color-border-light-gray;
	}
}

/* stylelint-enable max-line-length */

.sub-navigation .sub-navigation .sub-navigation__link-annotation {
	@media screen and ( min-width: 800px ) {
		display: none;
	}
}
