// Basic Colors
$color-black: #000;
$color-white: #fff;
$color-anthrazit: #555;
$color-dark-gray: #333;

// Main Colors
$color-bordeaux: #b01657;
$color-bordeaux-200: rgba( 176, 22, 86, .2 );
$color-petrol: #0096a9;
$color-petrol-100: rgba( 0, 150, 169, .1 );
$color-petrol-200: rgba( 0, 150, 169, .2 );
$color-yellow: #edaa00;
$color-yellow-200: rgba( 237, 170, 0, .2 );

// Accent Colors
$color-olive-green: #aaad00;
$color-olive-green-200: rgba( 170, 173, 0, .2 );
$color-blue: #407cca;
$color-blue-200: rgba( 64, 124, 202, .2 );
$color-orange-red: #ec554e;
$color-orange-red-200: rgba( 236, 85, 78, .2 );

// Alert Colors
$color-alert-success: $color-olive-green;
$color-alert-info: $color-blue;
$color-alert-error: $color-orange-red;
$color-alert-warning: $color-yellow;

// Text Colors
$color-text-copy: $color-anthrazit;
$color-text-copy-light: #aaa;
$color-text-destructive: $color-orange-red;

// Border Colors
$color-border-light-gray: rgba( 0, 0, 0, .1 );

// Color map for iterating over colors
$color-map: (
	bordeaux: $color-bordeaux,
	petrol: $color-petrol,
	yellow: $color-yellow,
	olive-green: $color-olive-green,
	blue: $color-blue,
	orange-red: $color-orange-red,
);
