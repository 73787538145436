@import "styles/shared";

.search-button {
	@media screen and ( min-width: 800px ) {
		@include reset-button;
	}

	&:hover,
	&:focus {
		.search-button__icon {
			color: tint( $color-petrol, 50% );

			.page-theme--bordeaux & {
				color: tint( $color-bordeaux, 50% );
			}

			.page-theme--yellow & {
				color: tint( $color-yellow, 50% );
			}

			.page-theme--olive-green & {
				color: tint( $color-olive-green, 50% );
			}

			.page-theme--blue & {
				color: tint( $color-blue, 50% );
			}

			.page-theme--orange-red & {
				color: tint( $color-orange-red, 50% );
			}

			.page-theme--anthrazit & {
				color: tint( $color-anthrazit, 50% );
			}
		}
	}

	/* Version in desktop header. */
	.navigation &.navigation__item {
		@media screen and ( min-width: 800px ) {
			position: relative;
			display: flex;
			align-items: center;
			gap: .5em;
			margin-left: calc( 1.5em + 2px );

			&::before {
				content: "";
				position: absolute;
				top: .45rem;
				bottom: .45rem;
				left: -1.5em;
				display: block;
				width: 2px;
				background-color: $color-text-copy;
				pointer-events: none;
			}
		}

		@media screen and ( min-width: 1000px ) {
			margin-left: calc( 2.5em + 2px );

			&::before {
				left: -2.5em;
			}
		}
	}

	.search-button__icon {
		@media screen and ( min-width: 800px ) {
			height: 20px;
			width: 20px;
			stroke-width: 3;
		}
	}
}
