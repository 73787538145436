@import "styles/shared";

.meta-navigation--profile {
	@media screen and ( min-width: 800px ) {
		position: relative;
	}

	.meta-navigation__list--not-logged-in {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		column-gap: 1rem;

		@media screen and ( max-width: 799px ) {
			flex-direction: row-reverse;
			justify-content: flex-end;
			padding-top: .625rem;
			padding-bottom: .625rem;
		}

		li {
			@media screen and ( max-width: 799px ) {
				border: 0;
			}
		}
	}

	.meta-navigation__list--logged-in {
		@media screen and ( max-width: 799px ) {
			li {
				margin-left: 34px;
			}
		}
	}

	.meta-navigation__dropdown--button {
		display: flex;
		align-items: center;

		@media screen and ( max-width: 799px ) {
			padding: 0 $inner-padding-small;
		}

		@media screen and ( min-width: 800px ) {
			gap: .2em;
		}

		span {
			padding: .625rem 0;
			line-height: 1;
		}

		svg {
			@media screen and ( min-width: 800px ) {
				width: 1.25em;
				transform: rotate( 90deg );
				transition: transform .2s;
			}
		}
	}

	&:hover .meta-navigation__dropdown--button svg {
		@media screen and ( min-width: 800px ) {
			transform: rotate( -90deg );
		}
	}

	.navigation__item {
		&--registration {
			padding: .375rem .5rem .4375rem .5rem;
			border: 2px solid #6f6f6f;
			border-radius: $border-radius-button;

			&:hover,
			&:focus {
				border-color: tint( $color-petrol, 50% );
				text-decoration: none;

				.page-theme--bordeaux & {
					border-color: tint( $color-bordeaux, 50% );
				}

				.page-theme--yellow & {
					border-color: tint( $color-yellow, 50% );
				}

				.page-theme--olive-green & {
					border-color: tint( $color-olive-green, 50% );
				}

				.page-theme--blue & {
					border-color: tint( $color-blue, 50% );
				}

				.page-theme--orange-red & {
					border-color: tint( $color-orange-red, 50% );
				}

				.page-theme--anthrazit & {
					border-color: tint( $color-anthrazit, 50% );
				}
			}

			&.navigation__item--active {
				border-color: $color-petrol;
			}
		}

		&--profile {
			padding-left: 1.25rem;
		}
	}

	img,
	.meta-navigation__dropdown--placeholder {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 2px solid #fff;
		box-shadow: 0 2px 4px 0 rgba( 0, 0, 0, .4 );
		margin-right: 10px;
		background-color: rgba( $color-anthrazit, .25 );
	}

	@media screen and ( max-width: 799px ) {
		svg {
			display: none;
		}

		> ul {
			li.meta-navigation__account {
				order: 0;
				padding-bottom: 1rem;
				margin-bottom: 1rem;
				border-bottom: 1px solid $color-border-light-gray;

				ul {
					margin-left: 0;
					justify-content: flex-end;
					flex-direction: row-reverse;
				}

				ul li {
					width: auto;
					padding-left: 0;
					margin-right: 1rem;
				}
			}
		}
	}
}
