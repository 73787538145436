@import "styles/shared";

.form {
	background: $color-white; // Todo: Remove, used for login.

	@media only screen and ( min-width: 690px ) {
		&--col-layout {
			display: flex;
			flex-wrap: wrap;
			margin-left: -.9375rem;
			margin-right: -.9375rem;

			.base-control {
				flex: 0 0 calc( 50% - 1.875rem );
				margin-left: .9375rem;
				margin-right: .9375rem;

				&--full-width {
					flex: 0 0 100%;
				}
			}

			.form__actions {
				margin-left: .9375rem;
				margin-right: .9375rem;
			}
		}
	}

	&__actions {
		width: 100%;
		text-align: right;
	}

	&__action-button {
		&:first-child {
			margin: 0 0 1.25rem 0;
		}
	}
}
