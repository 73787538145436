@import "styles/shared";

.notice-list {
	@include content-inner;
	position: sticky;
	top: 0;
	padding: 0;
	z-index: 20;
}

.notice {
	position: relative;
	color: $color-black;
	background-color: tint( $color-alert-info, 80% );
	border-left: .25rem solid $color-alert-info;
	margin: 0;
	padding: .9375rem 1.25rem;

	&--show-icon {
		margin-top: 2.25rem !important;

		&:first-child {
			margin-top: 1.25rem !important;

			@media screen and ( min-width: 800px ) {
				margin-top: 0 !important;
			}
		}
	}
}

.notice__icon {
	position: absolute;
	top: 0;
	left: 0;
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 50%;
	background-color: tint( $color-alert-info, 80% );
	border: .5rem solid tint( $color-alert-info, 80% );
	transform: translate3d( -25%, -50%, 0 );

	@media screen and ( min-width: 800px ) {
		transform: translate3d( -50%, -50%, 0 );
	}
}

.notice__dismiss {
	position: absolute;
	top: 50%;
	right: 0;
	background: none;
	border: 0;
	line-height: 1;
	color: $color-anthrazit;
	cursor: pointer;
	padding: .75rem;
	transform: translate3d( 0, -50%, 0 );

	&:hover {
		color: $color-bordeaux;
	}
}

.notice__dismiss-icon {
	height: 20px;
	width: 20px;
	fill: currentcolor;
}

.notice--is-success {
	background-color: tint( $color-alert-success, 80% );
	border-left-color: $color-alert-success;

	.notice__icon {
		background-color: tint( $color-alert-success, 80% );
		border-color: tint( $color-alert-success, 80% );

		path {
			stroke: $color-alert-success;
		}
	}
}

.notice--is-error {
	background-color: tint( $color-alert-error, 80% );
	border-left-color: $color-alert-error;

	.notice__icon {
		background-color: tint( $color-alert-error, 80% );
		border-color: tint( $color-alert-error, 80% );

		path {
			stroke: $color-alert-error;
		}
	}
}

.notice--is-warning {
	background-color: tint( $color-alert-warning, 80% );
	border-left-color: $color-alert-warning;

	.notice__icon {
		background-color: tint( $color-alert-warning, 80% );
		border-color: tint( $color-alert-warning, 80% );

		path {
			stroke: $color-alert-warning;
		}
	}
}
