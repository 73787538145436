@import "styles/shared";

.snackbar {
	font-size: 1rem;
	background-color: $color-dark-gray;
	border-radius: 2px;
	box-shadow: 0 1px 3px 0 rgba( 0, 0, 0, .4 );
	color: #fff;
	padding: 1rem 2rem;
	width: 100%;
	max-width: 600px;
	cursor: pointer;

	@media ( min-width: 600px ) {
		width: fit-content;
	}

	&:hover {
		background-color: $color-anthrazit;
	}

	&:focus {
		background-color: $color-anthrazit;
		box-shadow: 0 0 0 1px #fff;
	}

	&__action.button {
		margin-left: 32px;
		color: #fff;
		height: auto;
		flex-shrink: 0;
		text-decoration: underline;

		&:hover {
			color: #fff;
			text-decoration: none;
		}
	}

	&__content {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
	}
}

.snackbar-list {
	z-index: 100000;
	width: 100%;
	position: fixed;
	left: 0;
	bottom: .9375rem;
	padding-left: .9375rem;
	padding-right: .9375rem;

	@media screen and ( min-width: 800px ) {
		bottom: 1.5625rem;
		padding-left: 1.5625rem;
		padding-right: 1.5625rem;
	}

	@media screen and ( min-width: 1000px ) {
		bottom: 2.5rem;
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	&__notice-container {
		position: relative;
		padding-top: 8px;
	}
}
